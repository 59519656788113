<script>
import ENUMS from '@shared/messages/enums.json';

export default {
  messages: {
    status: ENUMS.bundleStatus,
    statusClass: ENUMS.bundleStatusClass,
  },
  props: {
    bundle: {
      type: Object,
      required: true,
    },
    reorderable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    schoolUrl() {
      const q = `?p=${this.$store.state.auth.previewToken}`;
      return `${this.$store.getters['auth/url']}/mon-espace/packs/${this.bundle.slug}${q}`;
    },
    salesUrl() {
      const { protocol } = window.location;
      return `${protocol}${this.$store.getters['auth/url']}/packs/${this.bundle.slug}`;
    },
  },
  methods: {
    updateStatus(status) {
      // if (this.bundle.status === status) {
      //   return;
      // }

      // if (['UNLISTED', 'HIDDEN'].includes(status) && this.$store.getters['auth/hasPercentPlan']) {
      //   this.$showMessage.goPro();
      //   return;
      // }

      const loader = this.$buefy.loading.open();
      this.$store.dispatch('bundles/update', {
        uuid: this.bundle.uuid,
        bundle: { status },
      })
        .finally(() => loader.close());
    },
    deleteItem() {
      this.$buefy.dialog.confirm({
        title: 'Cette action est définitive !',
        message: `
          <div class="content">
            <p>
              <strong>Attention</strong> !
              Vous allez supprimer le pack
              <strong>${this.bundle.name}</strong>.
            </p>
            <p class="has-text-danger has-text-weight-bold">
              Cette action est définitive !
            </p>
          </div>
        `,
        type: 'is-danger',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open();
          this.$store.dispatch('bundles/delete', this.bundle)
            .finally(() => loader.close());
        },
      });
    },
  },
};
</script>

<template>
  <div class="tscomp box pb-0 pr-0 overflow-hidden">
    <article class="media">
      <b-button v-if="reorderable" class="handle media-left is-hidden-mobile" type="is-text" icon-left="grip-lines"
        title="Cliquez et glissez pour réodonner" />
      <router-link class="media-left image is-128x128 cursor-pointer bradius-8 is-hidden-mobile"
        :class="{ 'has-background-grey': !bundle.picture }" :to="{ name: 'bundle', params: { uuid: bundle.uuid } }"
        tag="figure">
        <img v-if="bundle.picture" class="ofit-cover bradius-8" :style="{ width: '128px', height: '128px' }"
          :src="bundle.picture" :alt="bundle.name">
      </router-link>
      <div class="media-content">
        <p class="is-size-8 mb-3">
          <span class="tag mr-3">
            <b-icon class="mr-1 is-size-9" icon="circle" :type="$options.messages.statusClass[bundle.status]" />
            {{ $options.messages.status[bundle.status] }}
          </span>
          <span>
            <time>Créé le <strong>{{ bundle.created_at | momentFromUTC | moment('DD/MM/YY') }}</strong></time>

            <span class="mx-1">•</span>

            <time>Modifié le <strong>{{ bundle.updated_at | momentFromUTC | moment('DD/MM/YY') }}</strong></time>
          </span>
        </p>
        <h2 class="is-size-5 has-text-weight-bold">
          <router-link class="has-text-black" :to="{ name: 'bundle', params: { uuid: bundle.uuid } }">
            {{ bundle.name }}
          </router-link>
        </h2>

        <div class="is-flex pr-5 mt-7" style="gap: 6px;">
          <div v-for="(tag, index) in bundle.tags" :key="index" @click="$emit('selected-tag', { tag })">
            <b-tag type="is-primary" style="cursor:pointer;">
              {{ tag }}
            </b-tag>
          </div>
        </div>
      </div>
      <nav class="media-right buttons is-align-self-center">
        <b-button tag="router-link" :to="{ name: 'bundle', params: { uuid: bundle.uuid } }">
          Modifier
        </b-button>
        <b-dropdown position="is-bottom-left" aria-role="menu" append-to-body>
          <template #trigger>
            <b-button type="is-text" icon-left="eye" />
          </template>
          <b-dropdown-item has-link aria-role="menuitem">
            <a :href="salesUrl" target="_blank">
              Voir la page visiteur
            </a>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="mr-3" position="is-bottom-left" aria-role="menu" append-to-body>
          <template #trigger>
            <b-button type="is-text" icon-left="ellipsis-h" />
          </template>
          <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'bundle_settings', params: { uuid: bundle.uuid } }">
              Paramètres
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('PUBLISHED')">
            <b-icon icon="circle" type="is-success" class="is-size-9" />
            Publier
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('DRAFT')">
            <b-icon icon="circle" type="is-warning" class="is-size-9" />
            Mettre en brouillon
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('UNLISTED')">
            <b-icon icon="circle" type="is-info" class="is-size-9" />
            Ne plus répertorier
            <!-- <AppFeatureBadge class="ml-2" position="is-left" /> -->
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('HIDDEN')">
            <b-icon icon="circle" type="is-dark" class="is-size-9" />
            Cacher
            <!-- <AppFeatureBadge class="ml-2" position="is-left" /> -->
          </b-dropdown-item>
          <!-- <b-dropdown-item aria-role="menuitem" @click="updateStatus('CLOSED')">
            <b-icon icon="circle" type="is-danger" class="is-size-9" />
            Désactiver
          </b-dropdown-item> -->
          <!-- <b-dropdown-item separator /> -->
          <!-- <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'embeds', query: { formation: training.uuid } }">
              Intégrer en widget
            </router-link>
          </b-dropdown-item> -->
          <!-- <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{name: 'training_comments', params: {uuid: training.uuid}}">
              Partager
            </router-link>
          </b-dropdown-item> -->
          <!-- <b-dropdown-item separator /> -->
          <!-- <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'training_customers', params: { uuid: training.uuid } }">
              Apprenants
            </router-link>
          </b-dropdown-item> -->
          <!-- <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'training_comments', params: { uuid: training.uuid } }">
              Commentaires
            </router-link>
          </b-dropdown-item> -->
          <b-dropdown-item separator />
          <b-dropdown-item class="has-text-danger" aria-role="menuitem" @click="deleteItem">
            Supprimer
          </b-dropdown-item>
        </b-dropdown>
      </nav>
    </article>
    <footer class="-mx-5 mt-5 px-5 py-3 btop-grey">
      <p class="has-text-grey-dark">
        <span class="is-inline-block is-size-8">
          <b-icon icon="tag" />
          {{ bundle.price > 0 ? `${bundle.price} &euro;` : 'Gratuit' }}
        </span>
        <span class="is-inline-block is-size-8 ml-4">
          <b-icon icon="book" />
          {{ bundle.training_count }}
          {{ bundle.training_count > 1 ? 'formations' : 'formation' }}
        </span>
        <span class="is-inline-block is-size-8 ml-4">
          <b-icon icon="receipt" />
          {{ bundle.sell_count }}
          {{ bundle.sell_count > 1 ? 'ventes' : 'vente' }}
        </span>
        <span class="is-inline-block is-size-8 ml-4">
          <b-icon icon="cart-plus" />
          {{ bundle.abandoned_cart_count }}
          {{ bundle.abandoned_cart_count > 1 ? 'paniers abandonnés' : 'panier abandonné' }}
        </span>
      </p>
      <!-- <div class="is-flex pr-5" style="gap: 6px;">
        <b-tag
          v-for="(tag, index) in training.tags"
          :key="index"
          type="is-primary"
        >
          {{ tag }}
        </b-tag>
      </div> -->
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.tscomp {
  @include mobile {
    padding-right: pxToRem(20px) !important;

    .media {
      flex-wrap: wrap;
    }

    .media-right {
      width: 100%;
      margin: pxToRem(20px) 0 0;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  @include mobile {
    flex-direction: column;
  }
}
</style>
