var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"modal-card",on:{"submit":function($event){$event.preventDefault();_vm.dataIsValid(_vm.isLoading) && _vm.handle()}}},[_c('header',{staticClass:"modal-card-head"},[_c('h2',{staticClass:"modal-card-title"},[_vm._v(" Ajouter un pack ")]),_c('button',{staticClass:"modal-close is-large",attrs:{"type":"button","aria-label":"Fermer"},on:{"click":function($event){return _vm.$emit('close')}}})]),_c('div',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 pb-0"},[_c('BaseField',{attrs:{"field":{
          label: 'Titre',
          type: 'text',
          column: 'is-12 pb-0',
          inputAttrs: {
            required: true,
            maxlength: 128,
          },
        },"autofocus":""},model:{value:(_vm.bundle.name),callback:function ($$v) {_vm.$set(_vm.bundle, "name", $$v)},expression:"bundle.name"}})],1),_c('div',{staticClass:"column is-12 pb-0"},[_c('BaseField',{attrs:{"field":{
          label: 'Description courte',
          type: 'textarea',
          column: 'is-12 pt-0',
          inputAttrs: {
            maxlength: 200,
          },
        }},model:{value:(_vm.bundle.summary),callback:function ($$v) {_vm.$set(_vm.bundle, "summary", $$v)},expression:"bundle.summary"}})],1)])]),_c('footer',{staticClass:"modal-card-foot is-justify-content-flex-end"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-text"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Fermer ")]),_c('b-button',{attrs:{"type":"is-primary","native-type":"submit","loading":_vm.isLoading}},[_vm._v(" Ajouter ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }