<script>
import Draggable from 'vuedraggable';
// import trainingsMixin from '@app/mixins/trainings';
import TagInput from '@app/components/TagInput.vue';
import { debounce, isEqual } from 'lodash';
import BundleItem from '@app/components/BundleItem.vue';
import APITrainings from '@app/services/API/Trainings';
import BundleForm from '@app/components/BundleForm.vue';

const DEFAULT_SEARCH_FORM_DATA = {
  q: '',
  tags: [],
  sort_by: '',
  order_by: 'asc',
};

export default {
  // mixins: [trainingsMixin],
  components: {
    Draggable,
    TagInput,
    BundleItem,
  },
  head: {
    title: 'Vos Packs',
  },
  created() {
    this.isFetching = true;
    this.$store.commit('bundles/setSearchParams', this.searchFormData);
    this.$store.dispatch('bundles/count')
      .then(() => this.$store.dispatch('bundles/fetch'))
      .finally(() => (this.isFetching = false));

    APITrainings.getTrainings().then(({ data }) => {
      this.trainings = data;
    });
  },
  data: () => ({
    isFetching: false,
    searchFormData: { ...DEFAULT_SEARCH_FORM_DATA },
  }),
  computed: {
    count() {
      return this.$store.getters['bundles/count'];
    },
    bundles: {
      set(value) {
        this.$store.dispatch('bundles/reorder', value)
          .then(() => this.$showMessage.success());
      },
      get() {
        return (
          this.$store.state.bundles.list
          && this.$store.state.bundles.list.data
        );
      },
    },
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    hasExpertPlan() {
      return this.$store.getters['auth/hasExpertPlan'];
    },
    hasProPlan() {
      return this.$store.getters['auth/hasProPlan'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    debouncedSearch() {
      return debounce(this.search, 300);
    },
    canReorder() {
      return isEqual(DEFAULT_SEARCH_FORM_DATA, this.searchFormData);
    },
    searchParamsDirty() {
      return !isEqual(DEFAULT_SEARCH_FORM_DATA, this.searchFormData);
    },
  },
  methods: {
    async search() {
      this.isFetching = true;
      await this.$store.commit('bundles/setSearchParams', this.searchFormData);
      await this.$store.dispatch('bundles/fetch');
      this.isFetching = false;
    },
    toggleOrder() {
      this.searchFormData.order_by = this.searchFormData.order_by !== 'desc' ? 'desc' : 'asc';
      this.search(true);
    },
    resetSearchParams() {
      return this.searchFormData = { ...DEFAULT_SEARCH_FORM_DATA };
    },
    addBundle() {
      if (this.hasPercentPlan) {
        return this.$showMessage.goPro();
      }

      return this.$buefy.modal.open({
        parent: this,
        component: BundleForm,
        hasModalCard: true,
        trapFocus: true,
      }).then(() => this.$showMessage.success());
    },
    handleSortByChange(value) {
      this.searchFormData.sort_by = value;
      if (['bundle_items_count', 'customer_bundles_count'].includes(value)) {
        return this.searchFormData.order_by = 'desc';
      }
      return this.searchFormData.order_by = 'asc';
    },
  },
  watch: {
    searchFormData: {
      handler() {
        this.debouncedSearch();
      },
      deep: true,
    },
  },
};
</script>

<template>

  <section>
    <header class="level mb-0">
      <div class="level-left is-block w-80p">
        <h1 class="title is-size-4-mobile mb-2 is-flex is-align-items-center">
          <span>Vos Packs</span>
          <span v-if="!hasPercentPlan" class="ml-2">
            ({{ $store.state.bundles.count }} / {{ hasExpertPlan ? '∞' : 5 }})
          </span>
          <span v-if="isFetching" class="loader is-primary is-size-5 ml-4" />
        </h1>
      </div>
      <div class="level-right is-hidden-mobile">
        <b-button type="is-primary" icon-left="plus" @click="addBundle">
          Ajouter un pack
        </b-button>
      </div>
    </header>
    <template v-if="count === null || bundles === null">
      <div>
        <b-skeleton height="200" />
        <b-skeleton height="200" />
        <b-skeleton height="200" />
      </div>
    </template>
    <GoProWrapper :hasSep="false">
      <template v-if="count <= 0">
        <EmptyState title="Vous n'avez pas encore de pack"
          text="Cliquez sur le bouton ci-dessous pour en créer un dès maintenant." cta-text="Ajouter un pack"
          :cta-func="addBundle" />
      </template>
      <template v-else>
        <h3 class="mb-8 is-size-8">
          Créez vos packs en regroupant plusieurs formations pour offrir
          à vos apprenants une expérience complète<br>à un tarif avantageux.
          L'ordre d'affichage par défaut de vos packs sera le même dans votre
          <a :href="$store.getters['auth/url']" target="_blank">
            page d'accueil
          </a>.
        </h3>
        <!-- <h2 class="title is-7">
        L'ordre d'affichage de vos formations sera le même dans votre
        <a :href="$store.getters['auth/url']" target="_blank">
          page d'accueil
        </a>.
        <p v-if="!hasPercentPlan">
          Concernant vos apprenants connectés, vous pouvez choisir
          l'ordre d'affichage de leurs formations
          <router-link :to="{ name: 'customization_internal' }">
            ici
          </router-link>.
        </p>
      </h2> -->

        <form @submit.prevent="search" class="mb-5">
          <div class="columns">
            <div class="column">
              <b-field label="Filtrer par">
                <b-input v-model="searchFormData.q" placeholder="Filtrer par nom ou description"
                  :loading="isFetching" />
              </b-field>
            </div>

            <div class="column">
              <b-field label="Étiquette(s)">
                <TagInput v-model="searchFormData.tags" placeholder="Filtrer par étiquette" />
              </b-field>
            </div>

            <div class="column is-narrow">
              <b-field label="Trier par">
                <b-select :value="searchFormData.sort_by" @input="handleSortByChange">
                  <option value="" disabled>Ordre d'affichage public</option>
                  <option value="created_at">Date de création</option>
                  <option value="name">Nom</option>
                  <option value="bundle_items_count">Nombre formation</option>
                  <option value="customer_bundles_count">Nombre de vente</option>
                </b-select>
                <p class="control">
                  <b-tooltip :label="searchFormData.order_by == 'asc' ? 'croissant' : 'décroissant'">
                    <b-button :icon-left="searchFormData.order_by == 'asc' ? 'arrow-up' : 'arrow-down'"
                      @click="toggleOrder" />
                  </b-tooltip>
                </p>
              </b-field>
            </div>
          </div>
        </form>

        <a v-if="searchParamsDirty" href="#" class="is-size-8 level-right mb-6" @click.prevent="resetSearchParams"
          style="cursor:pointer;">
          <strong>Réinitialiser les paramètres de recherche</strong>
        </a>

        <draggable v-if="(bundles || []).length > 0" v-model="bundles" handle=".handle">
          <BundleItem v-for="bundle in bundles" :key="bundle.uuid" v-bind="{ bundle }" :reorderable="canReorder"
            @selected-tag="searchFormData.tags = [...searchFormData.tags, $event.tag]" />
        </draggable>

        <EmptyState v-else title="Aucun résultat trouvé" text="Aucun pack ne correspond à votre recherche."
          cta-text="Réinitialiser les paramètres de recherche" :cta-func="resetSearchParams" />
      </template>
    </GoProWrapper>
  </section>
</template>

<style lang="scss" scoped>
::v-deep .gpwcomp {
  padding-top: 1rem;
}
</style>
